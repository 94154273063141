import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/admin/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/stations',
    name: 'stations',
    component: () => import('@/views/admin/pages/Stations.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/routers',
    name: 'routers',
    component: () => import('@/views/admin/pages/Routers.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/routers/:id',
    name: 'routers.show',
    component: () => import('@/views/admin/pages/details/RouterDetails.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/internet-plans',
    name: 'internet-plans',
    exact: true,
    component: () => import('@/views/admin/pages/InternetPlans.vue'),
    props: route => ({ internetConnectionType: route.query.internet_connection_type }),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/ip-pools',
    name: 'ip-pools',
    component: () => import('@/views/admin/pages/IpPools.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/ip-networks',
    name: 'ip-networks',
    component: () => import('@/views/admin/pages/IpNetworks.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/ip-networks/:id',
    name: 'ip-networks.show',
    component: () => import('@/views/admin/pages/details/IpNetworkDetails.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/admin/pages/Customers.vue'),
    props: route => ({ internetConnectionType: route.query.internet_connection_type }),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/customers/:id',
    name: 'customers.show',
    component: () => import('@/views/admin/pages/details/CustomerDetails.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/admin/pages/Invoices.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/invoices/:id',
    name: 'invoices.show',
    component: () => import('@/views/admin/pages/details/InvoiceDetails.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('@/views/admin/pages/Sms.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/admin/pages/Payments.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/payments/:id',
    name: 'payments.show',
    component: () => import('@/views/admin/pages/details/PaymentDetails.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/admin/pages/Tickets.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/tickets/:id',
    name: 'tickets.show',
    component: () => import('@/views/admin/pages/details/TicketDetails.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/unresolved-payments',
    name: 'unresolved-payments',
    component: () => import('@/views/admin/pages/UnresolvedPayments.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/activity-logs',
    name: 'activity-logs',
    component: () => import('@/views/admin/pages/ActivityLogs.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/backups',
    name: 'backups',
    component: () => import('@/views/admin/pages/Backups.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/admin/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/admin/settings/Settings.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/analytics/payments',
    name: 'payment-analytics',
    component: () => import('@/views/admin/analytics/payments/Index.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/analytics/internet-plans',
    name: 'internet-plan-analytics',
    component: () => import('@/views/admin/analytics/internet-plans/Index.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/analytics/customers',
    name: 'customer-analytics',
    component: () => import('@/views/admin/analytics/customers/Index.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/analytics/sms',
    name: 'sms-analytics',
    component: () => import('@/views/admin/analytics/sms/Index.vue'),
    meta: {
      requiresAuth: true,
      roles: ['administrator'],
    },
  },
  {
    path: '/admin',
    redirect: { name: 'login' },
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import('@/views/admin/pages/InitialSetup.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['administrator'],
    },
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/admin/pages/Login.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['administrator'],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['administrator'],
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['administrator'],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      roles: ['administrator'],
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const { isUserAuthenticated } = store.getters
  const { userInfo } = store.getters

  if (to.name === 'login' && isUserAuthenticated) {
    return next({ name: 'dashboard' })
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !isUserAuthenticated) {
    return next({ name: 'login' })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const userRoles = userInfo?.roles

    const isAdmin = userRoles?.includes('administrator') || userRoles?.includes('admin') || userRoles?.includes('super-admin')

    if (!isAdmin) {
      return next({ path: 'error-403' })
    }

    return next()
  }

  return next()
})
export default router
