<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="280"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="60px"
          max-width="60px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{ companyName }}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <template v-for="(item, index) in navigationItems">
        <template v-if="item.children">
          <nav-menu-group
            :key="item.title"
            :title="item.title"
            :icon="item.icon"
          >
            <template v-for="child in item.children">
              <nav-menu-link
                :key="child.title"
                class="ml-4"
                :title="child.title"
                :to="child.to"
                :icon="child.icon"
                :is-new="item.isNew"
                exact
              ></nav-menu-link>
            </template>
          </nav-menu-group>
        </template>
        <template v-else>
          <nav-menu-link
            :key="item.title"
            :title="item.title"
            :to="item.to"
            :icon="item.icon"
            :is-new="item.isNew"
          ></nav-menu-link>
        </template>
      </template>
    </v-list>
    <nav-menu-link
      title="Settings"
      :to="{ name: 'settings' }"
      :icon="mdiCog"
      class="settings-menu"
    ></nav-menu-link>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  mdiHomeOutline,
  mdiAccountGroup,
  mdiReceipt,
  mdiChatOutline,
  mdiHomeGroup,
  mdiWeb,
  mdiCurrencyUsdCircleOutline,
  mdiCurrencyUsdOff,
  mdiRouterNetwork,
  mdiNetworkOutline,
  mdiIp,
  mdiIpNetwork,
  mdiWan,
  mdiMinus,
  mdiArrowSync,
  mdiCashMultiple,
  mdiAccessPoint,
  mdiCloudUpload,
  mdiTimeline,
  mdiPoll,
  mdiCog,
  mdiTicket,
} from '@mdi/js'
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import hasPermission from '@/mixins/hasPermission'

const customerIcons = {
  all: mdiAccountGroup,
  [internetConnectionTypeEnum.PPPOE]: mdiWan,
  [internetConnectionTypeEnum.STATIC]: mdiMinus,
  [internetConnectionTypeEnum.DYNAMIC]: mdiArrowSync,
  [internetConnectionTypeEnum.HOTSPOT]: mdiAccessPoint,
}

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  mixins: [hasPermission],
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      navigationItems: [
        {
          title: 'Dashboard',
          to: { name: 'dashboard' },
          icon: mdiHomeOutline,
        },
      ],
      mdiCog,
    }
  },
  computed: {
    ...mapGetters(['companyName']),
  },
  mounted() {
    this.setOptionalNavigationItems()
  },
  methods: {
    setOptionalNavigationItems() {
      this.addStationMenu()
      this.addNetworkingMenu()
      this.addInternetPlansMenu()
      this.addCustomerPlansMenu()
      this.addFinanceMenu()
      this.addTicketsMenu()
      this.addSmsMenu()
      this.addBackupMenu()
      this.addActivityLogMenu()
      this.addAnalyticsMenu()
    },
    addStationMenu() {
      if (this.can('station-list')) {
        this.navigationItems.push({
          title: 'Stations',
          to: { name: 'stations' },
          icon: mdiHomeGroup,
        })
      }
    },
    addNetworkingMenu() {
      if (this.can('router-list') || this.can('ip-pool-list')) {
        const networkingMenu = {
          title: 'Networking',
          icon: mdiNetworkOutline,
          children: [],
        }

        if (this.can('router-list')) {
          networkingMenu.children.push({
            title: 'Routers',
            to: { name: 'routers' },
            icon: mdiRouterNetwork,
          })
        }

        if (this.can('ip-network-list')) {
          networkingMenu.children.push({
            title: 'IP Networks',
            to: { name: 'ip-networks' },
            icon: mdiIpNetwork,
          })
        }

        if (this.can('ip-pool-list')) {
          networkingMenu.children.push({
            title: 'IP Pools',
            to: { name: 'ip-pools' },
            icon: mdiIp,
          })
        }

        this.navigationItems.push(networkingMenu)
      }
    },
    addInternetPlansMenu() {
      if (this.can('internet-plan-list')) {
        const internetPlansMenu = {
          title: 'Internet Plans',
          icon: mdiWeb,
          children: [
            { title: 'PPPOE Plans', type: internetConnectionTypeEnum.PPPOE, icon: mdiWan },
            { title: 'Static IP Plans', type: internetConnectionTypeEnum.STATIC, icon: mdiMinus },
            { title: 'Dynamic IP Plans', type: internetConnectionTypeEnum.DYNAMIC, icon: mdiArrowSync },
            { title: 'Hotspot Plans', type: internetConnectionTypeEnum.HOTSPOT, icon: mdiAccessPoint },
          ].map(plan => ({
            title: plan.title,
            to: { name: 'internet-plans', query: { internet_connection_type: plan.type } },
            icon: plan.icon,
          })),
        }
        this.navigationItems.push(internetPlansMenu)
      }
    },
    addCustomerPlansMenu() {
      if (this.can('customer-list')) {
        const customerPlansMenu = {
          title: 'Customers',
          icon: mdiAccountGroup,
          children: [
            { title: 'All Customers', type: 'all' },
            { title: 'PPPOE Customers', type: internetConnectionTypeEnum.PPPOE },
            { title: 'Static Customers', type: internetConnectionTypeEnum.STATIC },
            { title: 'Dynamic Customers', type: internetConnectionTypeEnum.DYNAMIC },
            { title: 'Hotspot Customers', type: internetConnectionTypeEnum.HOTSPOT },
          ].map(customer => ({
            title: customer.title,
            to: { name: 'customers', query: { internet_connection_type: customer.type } },
            icon: customerIcons[customer.type],
            exact: true,
          })),
        }
        this.navigationItems.push(customerPlansMenu)
      }
    },
    addFinanceMenu() {
      if (this.can('payment-list')) {
        const financeMenu = {
          title: 'Finance',
          icon: mdiCashMultiple,
          children: [
            { title: 'Invoices', to: { name: 'invoices' }, icon: mdiReceipt },
            { title: 'Payments', to: { name: 'payments' }, icon: mdiCurrencyUsdCircleOutline },
            { title: 'Unresolved Payments', to: { name: 'unresolved-payments' }, icon: mdiCurrencyUsdOff },
          ],
        }
        this.navigationItems.push(financeMenu)
      }
    },
    addSmsMenu() {
      if (this.can('sms-list')) {
        this.navigationItems.push({
          title: 'SMS',
          to: { name: 'sms' },
          icon: mdiChatOutline,
        })
      }
    },
    addBackupMenu() {
      if (this.can('router-backup-list')) {
        this.navigationItems.push({
          title: 'Backups',
          to: { name: 'backups' },
          icon: mdiCloudUpload,
        })
      }
    },
    addActivityLogMenu() {
      if (this.can('activity-log-list')) {
        this.navigationItems.push({
          title: 'Activity Logs',
          to: { name: 'activity-logs' },
          icon: mdiTimeline,
        })
      }
    },
    addAnalyticsMenu() {
      if (this.can('analytics-list')) {
        const analyticsMenu = {
          title: 'Analytics',
          icon: mdiPoll,
          children: [
            { title: 'Internet Plans', to: { name: 'internet-plan-analytics' }, icon: mdiWeb },
            { title: 'Customers', to: { name: 'customer-analytics' }, icon: mdiAccountGroup },
            { title: 'Payments', to: { name: 'payment-analytics' }, icon: mdiCurrencyUsdCircleOutline },
            { title: 'Sms', to: { name: 'sms-analytics' }, icon: mdiChatOutline },
          ],
        }
        this.navigationItems.push(analyticsMenu)
      }
    },
    addTicketsMenu() {
      if (this.can('ticket-list')) {
        this.navigationItems.push({
          title: 'Tickets',
          to: { name: 'tickets' },
          icon: mdiTicket,
          isNew: true,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list {
    margin-bottom: 48px; /* Adjust the value as needed to create space between menu items and the Settings menu */
  }
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
  .settings-menu {
    position: absolute;
    bottom: 0; /* Adjust the value as needed */
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    background-color: var(--v-aura-base);
    z-index: 1; /* Ensure it appears above other menu items */
  }
}
</style>
